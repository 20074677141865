<template>
  <div id="app" style="background-image: url('./img/color.jpg'); background-size: cover; background-attachment: fixed; height: 100%;">
    <!-- ======= Header ======= -->
        <header id="header" class="header fixed-top d-flex align-items-center">
  
          <div class="d-flex align-items-center justify-content-between">
            <a href="/dashboard" class="logo d-flex align-items-center" style="position: relative;">
              <img src="./img/dilg-logo1.png" alt="" 
                   style="position: absolute; max-height: 220px; max-width: 220px; margin-left: -30px; z-index: 1;">
              <span style="font-family: 'Times New Roman', Times, serif; font-size: 25px; color: rgb(42, 43, 72); padding-left: 120px; z-index: 2; position: relative;">
                INVENTrack
              </span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn"></i>
          </div><!-- End Logo -->
  
      <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
  
  
          <!-- Notification Icon -->
          <li class="nav-item dropdown">
            <a class="nav-link nav-icon" href="#" @click="fetchNotifications" data-bs-toggle="dropdown">
              <i class="bi bi-bell"></i>
              <span class="badge bg-danger badge-number">{{ unreadCount }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" @click.stop>
              <!-- Title and Tabs -->
              <li class="dropdown-header">
                <span class="notifications-title">Notifications</span>
                <nav class="notifications-nav">
                  <button @click="filterNotifications('all')" :class="{ active: filter === 'all' }">All</button>
                  <button @click="filterNotifications('unread')" :class="{ active: filter === 'unread' }">Unread</button>
                </nav>
              </li>
              <hr />

              <!-- Notifications List -->
              <li
              v-for="notification in filteredNotifications"
              :key="notification.id"
              :class="['dropdown-item', notification.status === 'unread' ? 'notification-unread' : 'notification-read']"
              @click="markAsRead(notification.id)"
              >
                <div class="notification-content">
                  <!-- Icon in a white circle -->
                  <div class="notification-icon-circle">
                    <i :class="notification.icon"></i> <!-- Icon from the database -->
                  </div>
              
                  <!-- Message and Time -->
                  <div class="notification-details">
                    <span class="notification-message">{{ truncateMessage(notification.message) }}</span>
                    <span class="notification-time">{{ computeTimeAgo(notification.created_at) }}</span> <!-- Time below the message -->
                  </div>
              
                  <!-- Unread Indicator Circle -->
                  <span class="notification-indicator" v-if="notification.status === 'unread'"></span>
                </div>
              </li>
            
              <li v-if="filteredNotifications.length === 0" class="dropdown-item text-center">No notifications</li>
            </ul>
          </li>

  
          <li class="nav-item dropdown pe-3">
  
            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
              <div style="width: 50px; height: 50px; overflow: hidden; border-radius: 50%;">
                <div :style="getImageStyle(infos.image)"></div>
              </div>
              <span class="d-none d-md-block dropdown-toggle ps-2">{{ infos.fullname }}</span>
            </a><!-- End Profile Image Icon -->

            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li class="dropdown-header">
                <h6>{{ infos.fullname }}</h6>
                <span>{{ infos.position }}</span>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                  <i class="bi bi-person"></i>
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                  <i class="bi bi-gear"></i>
                  <span>Account Settings</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                  <i class="bi bi-question-circle"></i>
                  <span>Need Help?</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)" @click="logout">
                  <i class="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
  
            </ul><!-- End Profile Dropdown Items -->
          </li><!-- End Profile Nav -->
  
        </ul>
      </nav><!-- End Icons Navigation -->
  
      </header><!-- End Header -->
  
  
  
  
  
  
  
  
      <!-- ======= Sidebar ======= -->
      <aside id="sidebar" class="sidebar">
  
        <ul class="sidebar-nav" id="sidebar-nav">
    
          
          <li class="nav-heading">Home</li>
    
          <li class="nav-item">
            <a class="nav-link collapsed" href="/dashboard">
              <i class="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li><!-- End Dashboard Nav -->
    
          
          <!-- Pages Section -->
          <li class="nav-heading">Pages</li>
          <li class="nav-item">
            <a class="nav-link active" href="databaseppe">
              <i class="bi bi-clipboard-data"></i>
              <span>Database PPE</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
              <i class="bi bi-menu-button-wide"></i><span>PROPERTY, PLANT AND EQUIPMENT</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li>
                <a href="/serviceable">
                  <i class="bi bi-clipboard-check"></i><span>Serviceable</span>
                </a>
              </li>
              <li>
                <a href="unserviceable">
                  <i class="bi bi-clipboard-x"></i><span>Unserviceable</span>
                </a>
              </li>
              <li>
                <a href="returnedppe">
                  <i class="bi bi-box-arrow-left"></i><span>Returned PPE</span>
                </a>
              </li>
              <li>
                <a href="transferedppe">
                  <i class="bi bi-box-arrow-right"></i><span>Transfered PPE</span>
                </a>
            </li>
            <li>
                <a href="disposedppe">
                  <i class="bi bi-trash"></i><span>Disposed PPE</span>
                </a>
            </li>
            </ul>
          </li><!-- End Components Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
              <i class="bi bi-journal-text"></i><span>Documents</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li>
                <a href="propertysticker">
                  <i class="bi bi-sticky"></i><span>Property Sticker</span>
                </a>
              </li>
              <li>
                <a href="ledgercard">
                  <i class="bi bi-folder2-open"></i><span>PPE Documents</span>
                </a>
              </li>
            </ul>
          </li><!-- End Forms Nav -->
          <!-- Input Section -->
          <li class="nav-heading">input</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/workspace">
              <i class="bi bi-pencil-square"></i>
              <span>Workspace</span>
            </a>
          </li><!-- End Input Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" href="/logbook">
              <i class="bi bi-calendar-check"></i>
              <span>Logbook</span>
            </a>
          </li><!-- End Input Nav -->
          <!-- Stocks Section -->
          <li class="nav-heading">Stocks</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/inventory">
              <i class="bi bi-box-seam"></i>
              <span>Inventory</span>
            </a>
          </li><!-- End Stocks Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" href="/supplies">
              <i class="bi bi-stack"></i>
              <span>Supplies</span>
            </a>
          </li>
          <!-- Ordering Section -->
          <li class="nav-heading">Ordering</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/ordering">
              <i class="bi bi-shop"></i>
              <span>Ordering</span>
            </a>
          </li><!-- End Ordering Nav -->
          <!-- Security Section -->
          <li class="nav-heading">Security</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/userverify">
              <i class="bi bi-person-check"></i>
              <span>User Verification</span>
            </a>
          </li><!-- End Security Nav -->
        </ul>
      </aside><!-- End Sidebar-->
  
  
      <main id="main" class="main">

        <div class="pagetitle">
        <h1>Database - Property, Plant and Equipment</h1>
        <nav>
            <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li class="breadcrumb-item">Tables</li>
            <li class="breadcrumb-item active">Data</li>
            </ol>
        </nav>
        </div><!-- End Page Title -->

        <section class="section">

        
        <div class="row justify-content-center">
  <div class="col-lg-12">
    <div class="card" style="border-top: 30px solid #80faf4; border-bottom: 30px solid #ffa341;">
      <div class="card-body">

<!-- HTML Template -->
<div class="row justify-content-center align-items-center">
  <!-- Dropdown for Employee -->
  <div class="col-lg-2">
    <!-- Show Entries Dropdown -->
    <div class="d-flex align-items-center">
      <span class="me-2">Show</span>
      <div class="dropdown" style="display: inline-block;">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="showEntriesDropdown" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: white; color: black;">
          {{ pageSize }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="showEntriesDropdown">
          <li><a class="dropdown-item" href="#" @click="updatePageSize(10)">10</a></li>
          <li><a class="dropdown-item" href="#" @click="updatePageSize(20)">20</a></li>
          <li><a class="dropdown-item" href="#" @click="updatePageSize(30)">30</a></li>
          <!-- Add more options as needed -->
        </ul>
      </div>
      <span class="ms-2">entries</span>
    </div>
  </div>
  <div class="col-lg-2">
    <select v-model="selectedEmployee" class="form-select" @change="onEmployeeChange">
      <option value="">Select Employee</option>
      <option v-for="acc_officer in distinctEmployees" :key="acc_officer" :value="acc_officer">{{ acc_officer }}</option>
    </select>
  </div>

  <!-- Dropdown for Classification -->
  <div class="col-lg-2">
    <select v-model="selectedClassification" class="form-select" :disabled="!selectedEmployee" @change="onEmployeeChange">
      <option value="">Select Classification</option>
      <option v-for="classification in distinctClassification" :key="classification" :value="classification">{{ classification }}</option>
    </select>
  </div>

  <!-- Dropdown for Article -->
  <div class="col-lg-2">
    <select v-model="selectedArticle" class="form-select" :disabled="!selectedEmployee || !selectedClassification" @change="onEmployeeChange">
      <option value="">Select Article</option>
      <option v-for="article in distinctArticle" :key="article" :value="article">{{ article }}</option>
    </select>
  </div>

  <!-- Dropdown for Particular -->
  <div class="col-lg-2">
    <select v-model="selectedParticular" class="form-select" :disabled="!selectedEmployee || !selectedClassification || !selectedArticle">
      <option value="">Select Particular</option>
      <option v-for="particular in distinctParticular" :key="particular" :value="particular">{{ particular }}</option>
    </select>
  </div>

  <!-- Status dropdown -->
  <div class="col-lg-2">
    <!-- <select v-model="selectedRemarks" class="form-select">
      <option value="">Remarks</option>
      <option v-for="remarks in distinctRemarks" :key="remarks" :value="remarks">{{ remarks }}</option>
    </select> -->
    <div class="InputContainer">
      <input placeholder="Search.." id="input" class="input" name="text" type="text" v-model="searchKeyword">
    </div>
  </div>

  <!-- <div class="col-lg-3">
    <div class="accordion" id="faq-group-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed btn btn-outline-info" data-bs-target="#faqsTwo-1" type="button" data-bs-toggle="collapse">
            Download
          </button>
        </h2>
        
        <div id="faqsTwo-1" class="accordion-collapse collapse" data-bs-parent="#faq-group-2">
          <div class="row align-items-center mt-3">
            <div class="col-lg-6">
              <select v-model="selectedEmployeePDF" @change="downloadEmployeeRecordsPDF" class="form-select animated-dropdown w-100">
                <option value="" disabled>Select Employee</option>
                <option v-for="acc_officer in distinctEmployees" :key="acc_officer" :value="acc_officer">{{ acc_officer }}</option>
              </select>
            </div>
            <div class="col-lg-6">
              <button class="btn btn-warning w-100" @click="recordsPDF"><i class="ri-download-2-line"></i> Download All</button>
            </div>
          </div>
          <div v-if="loading" class="text-center mt-1">
            <div class="loading-line"></div>
          </div>
        </div>
      </div> 
    </div> 
  </div> -->




<br>
<br>
<br>
<br>



<!-- Search Bar and Show Entries Dropdown -->

</div>


<div class="wrapper">
  <table class="table-compact">
                <!-- Table header -->
                <thead>
                  
                  <tr>
        <th rowspan="2"><b>Image</b></th>
        <th rowspan="2"><b>Entity Name</b></th>
        <th rowspan="2"><b>Semi-expendable</b><br> 
                        <b>Property Classification</b></th>
        <th rowspan="2"><b>UACS</b><br>
                        <b>Object Code:</b></th>

        <th colspan="4"><b>Description</b></th>

        <th rowspan="2"><b>Full Description</b></th>
        <th rowspan="2">
          <b>Semi-expendable</b><br>
          <b>Property Number:</b>
        </th>
      
        <th rowspan="2"><b>Date</b></th>

        <th colspan="2"><b>Reference</b></th>
        <th colspan="4" style="background-color: blue; color: white;"><b>Receipt</b></th>

        <th rowspan="2" style="background-color: blue; color: white;"><b>"Issue/ Transfer/</b><br>
                                                                          <b> Adjustments (Amount)"</b></th>
        <th rowspan="2" style="background-color: blue; color: white;"><b>Accummulated</b><br><b>Impairment Loss</b></th>
        <th rowspan="2" style="background-color: blue; color: white;"><b>Adjusted Cost</b></th>

        <th colspan="2" style="background-color: blue; color: white;"><b>History of Repair</b></th>
        <th colspan="4" style="background-color: #20ff6a; color: black;"><b>Issue</b></th>
        <th colspan="3" style="background-color: #07ad41; color: black;"><b>Transfer</b></th>
        <th colspan="3" style="background-color: orange; color: black;"><b>Disposal</b></th>
        <th colspan="1"><b>Balance</b></th>

        <th rowspan="2"><b>Amount</b></th>
        <th rowspan="2"><b>Remarks</b></th>
        <th rowspan="2"><b>ESTIMATED</b><br><b>USEFUL</b><br><b>LIFE</b></th>

        <th colspan="3" style="background-color: #16fa54; color: black;"><b>Received from: (Issued by)</b></th>
        <th colspan="3" style="background-color: #16fa54; color: black;"><b>Received by: (Accountable Officer)</b></th>
        <th colspan="2" style="background-color: #07ad41; color: black;"><b>INVENTORY TRANSFER REPORT</b></th>
        <th colspan="3" style="background-color: #07ad41; color: black;"><b></b></th>

        <th rowspan="2" style="background-color: blue; color: white;"><b>"REPORT OF SEMI-EXP PROP ISSUEDSERIAL NO.:"</b></th>

        <th colspan="2" style="background-color: blue; color: white;"><b>Returned</b></th>
        <th colspan="2" style="background-color: blue; color: white;"><b>Re-issued</b></th>
        <th colspan="1" style="background-color: blue; color: white;"><b>Disposed</b></th>
        <th colspan="1" style="background-color: blue; color: white;"><b>Balance</b></th>

        <th rowspan="2" style="background-color: blue; color: white;"><b>Amount</b></th>
        <th rowspan="2" style="background-color: blue; color: white;"><b>Remarks</b></th>
        <th rowspan="2"><b>Property Officer</b></th>
        <th rowspan="2"><b>Head of Office/ Approving Authority</b></th>
       

    </tr>
    <tr>
        <th><b>Article</b></th>
        <th><b>Particulars</b></th>
        <th><b>Model No.</b></th>
        <th><b>Serial No.</b></th>

        <th><b>ICS No.</b></th>
        <th><b>JEV No.</b></th>

        <th style="background-color: blue; color: white;"><b>Quantity</b></th>
        <th style="background-color: blue; color: white;"><b>Unit</b></th>
        <th style="background-color: blue; color: white;"><b>Unit Cost</b></th>
        <th style="background-color: blue; color: white;"><b>Total Cost</b></th>

        <th style="background-color: blue; color: white;"><b>Nature of Repair</b></th>
        <th style="background-color: blue; color: white;"><b>Amount</b></th>

        <th style="background-color: #20ff6a; color: black;"><b>Item No.</b></th>
        <th style="background-color: #20ff6a; color: black;"><b>Date</b></th>
        <th style="background-color: #20ff6a; color: black;"><b>Quantity</b></th>
        <th style="background-color: #20ff6a; color: black;"><b>Office/Officer</b></th>

        <th style="background-color: #07ad41; color: black;"><b>Date</b></th>
        <th style="background-color: #07ad41; color: black;"><b>Quantity</b></th>
        <th style="background-color: #07ad41; color: black;"><b>Office/Officer</b></th>

        <th style="background-color: orange; color: black;"><b>Date</b></th>
        <th style="background-color: orange; color: black;"><b>Quantity</b></th>
        <th style="background-color: orange; color: black;"><b>Office/Officer</b></th>

        <th><b>Quantity</b></th>

        <th style="background-color: #16fa54; color: black;"><b>Name</b></th>
        <th style="background-color: #16fa54; color: black;"><b>Position/Office</b></th>
        <th style="background-color: #16fa54; color: black;"><b>Date</b></th>
        
        <th style="background-color: #16fa54; color: black;"><b>Name</b></th>
        <th style="background-color: #16fa54; color: black;"><b>Position</b></th>
        <th style="background-color: #16fa54; color: black;"><b>Date</b></th>

        <th style="background-color: #07ad41; color: black;"><b>"ITR NO.(YYYY-MON-NO. SERIES-RCC)"</b></th>
        <th style="background-color: #07ad41; color: black;"><b>ITR DATE</b></th>

        <th style="background-color: #07ad41; color: black;"><b>"RRSP NO.(YYYY-MON-NO. SERIES-RCC)"</b></th>
        <th style="background-color: #07ad41; color: black;"><b>RRSP DATE</b></th>
        <th style="background-color: #07ad41; color: black;"><b>REASON FOR TRANSFER</b></th>

        <th style="background-color: blue; color: white;"><b>Quantity</b></th>
        <th style="background-color: blue; color: white;"><b>Office/Officer</b></th>
        <th style="background-color: blue; color: white;"><b>Quantity</b></th>
        <th style="background-color: blue; color: white;"><b>Office/Officer</b></th>
        <th style="background-color: blue; color: white;"><b>Quantity</b></th>
        <th style="background-color: blue; color: white;"><b>Quantity</b></th>

    </tr>
                </thead>
                
                <tbody>
                  <!-- Table rows -->
                  <tr v-for="info in paginatedInfo" :key="info.id" @click="storeRecordId(info.id)">
                  
                    <td scope="row"><img :src="`https://inventrack.online/backend/uploads/${info.image}`" alt="Inventory Image" style="max-width: 60px; max-height: 60px;" /></td>
                    <td scope="row">{{ info.entityname }}</td>
                    <td scope="row">{{ info.classification }}</td>
                    <td scope="row">{{ info.code }}</td>
                    <td scope="row">{{ info.article }}</td>
                    <td scope="row">{{ info.particulars }}</td>
                    <td scope="row">{{ info.modelno }}</td>
                    <td scope="row">{{ info.serialno }}</td>
                    <td scope="row">{{ info.fulldescription }}</td>
                    <td scope="row">{{ info.propertynumber }}</td>
                    <td scope="row">{{ info.propertydate }}</td>
                    <td scope="row">{{ info.icsnumber }}</td>
                    <td scope="row">{{ info.jevnumber }}</td>
                    <td scope="row">{{ info.rec_quantity }}</td>
                    <td scope="row">{{ info.rec_unit }}</td>
                    <td scope="row">{{ info.rec_unitcost }}</td>
                    <td scope="row">{{ info.rec_totalcost }}</td>
                    <td scope="row">{{ info.isstranadjamount }}</td>
                    <td scope="row">{{ info.accimploss }}</td>
                    <td scope="row">{{ info.adjustedcost }}</td>
                    <td scope="row">{{ info.repair_nature }}</td>
                    <td scope="row">{{ info.repair_amount }}</td>
                    <td scope="row">{{ info.issue_itemno }}</td>
                    <td scope="row">{{ info.issue_date }}</td>
                    <td scope="row">{{ info.issue_quantity }}</td>
                    <td scope="row">{{ info.issue_officeofficer }}</td>
                    <td scope="row">{{ info.transfer_date }}</td>
                    <td scope="row">{{ info.transfer_quantity }}</td>
                    <td scope="row">{{ info.transfer_officeofficer }}</td>
                    <td scope="row">{{ info.disposal_date }}</td>
                    <td scope="row">{{ info.disposal_quantity }}</td>
                    <td scope="row">{{ info.disposal_officeofficer }}</td>
                    <td scope="row">{{ info.balancequantity }}</td>
                    <td scope="row">{{ info.balanceamount }}</td>
                    <td scope="row">{{ info.remarks }}</td>
                    <td scope="row">{{ info.estimatedlife }}</td>
                    <td scope="row">{{ info.issued_officer }}</td>
                    <td scope="row">{{ info.issued_offposition }}</td>
                    <td scope="row">{{ info.issued_date }}</td>
                    <td scope="row">{{ info.acc_officer }}</td>
                    <td scope="row">{{ info.acc_empposition }}</td>
                    <td scope="row">{{ info.acc_date }}</td>
                    <td scope="row">{{ info.itr_no }}</td>
                    <td scope="row">{{ info.itr_date }}</td>
                    <td scope="row">{{ info.rrsp_no }}</td>
                    <td scope="row">{{ info.rrsp_date }}</td>
                    <td scope="row">{{ info.reasonfortrans }}</td>
                    <td scope="row">{{ info.reg_semiissuedserialno }}</td>
                    <td scope="row">{{ info.reg_returned_qty }}</td>
                    <td scope="row">{{ info.reg_returned_off }}</td>
                    <td scope="row">{{ info.reg_reissued_qty }}</td>
                    <td scope="row">{{ info.reg_reissued_off }}</td>
                    <td scope="row">{{ info.reg_disposed_qty }}</td>
                    <td scope="row">{{ info.reg_balance_quantity }}</td>
                    <td scope="row">{{ info.reg_amount }}</td>
                    <td scope="row">{{ info.reg_remarks }}</td>
                    <td scope="row">{{ info.property_officer }}</td>
                    <td scope="row">{{ info.approving_authority }}</td>
                  </tr>
                </tbody>
              </table>
                </div>
                <!-- End Table with stripped rows -->
<br>
                <!-- I-update ang table at idagdag ang pagination controls -->
                <div class="card-body">
  <!-- Iba pang content ng card... -->
  <div class="text-center">
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center mb-0"> <!-- Center pagination -->
        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="currentPage = Math.max(currentPage - 1, 1)">Previous</a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ 'active': currentPage === page }">
          <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="currentPage = Math.min(currentPage + 1, totalPages)"><b>Next</b></a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="mt-3">
    <p>{{ currentPageRecords }}</p> <!-- Moved current page records here -->
  </div>
</div>

                </div>
            </div>
        </div>
        </div>

        <!-- <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <br>
                <h5>Choose Employee</h5>
                <br>
                <select v-model="selectedshowEmployeePDF" @change="updatePdfUrl" class="form-select animated-dropdown w-100">
                  <option value="">Select Employee</option>
                  <option v-for="employee in distinctEmployees" :key="employee" :value="employee">
                    {{ employee }}
                  </option>
                </select>
              </div>
              <div class="card-body">
                <div>
                  <iframe v-if="pdfUrl" :src="pdfUrl" style="width: 100%; height: 100vh;" frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
      </div> -->

          <!-- Modal component to display selected record -->
          <div class="modal" v-if="selectedInfo">
            <div class="modal-content" style="
                  font-family: Calibri;
                  font-size: 14px;
                  line-height: 1.5;
                  color: #333;
                  background-color: #f9f9f9;
                  padding: 20px;">
              <span class="close" @click="selectedInfo = null">&times;</span>
              <div class="col-lg-16">
                <div class="container" style="
                      width: 70%;
                      max-width: 600px;
                      margin: 0 auto;
                      border: 1px solid #ccc;
                      padding: 20px;
                      background-color: #fff;
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                  <div class="header" style="text-align: center; margin-bottom: 20px;">
                    <p>Republic of the Philippines
                      <br>
                      <b>Department of the Interior and Local Government</b>
                      <br>
                      MIMAROPA Region
                      <br>
                      <i style="color: red;"><b>PROPERTY INVENTORY STICKER</b></i>
                    </p>
                  </div>
                  <div class="property-info">
                    <p>Property No./Description : <b style="color: blue;">MDO - LT - 2022-02</b>
                      <br>
                      <b style="color: blue;">{{selectedInfo.fulldescription}}</b>
                      __________________________________________________
                      <br>
                      Model No.: <b style="color: blue;">{{selectedInfo.modelno}}</b>
                      <br>
                      Serial No.: <b style="color: blue;">{{selectedInfo.serialno}}</b><img :src="generateQRCodeUrl(selectedInfo.propertynumber)" alt="" style="max-width: 70px; max-height: 70px; float: right;">
                      <br>
                      Acquisition Date/Cost: <b style="color: blue;"> 2021 - 7,999.00 </b>
                      <br>
                      Person Accountable: <b style="color: blue;">{{selectedInfo.acc_officer}}</b>
                      <br>
                      Remarks: <b style="color: blue;"> Ronel O. Cacho/Oriental Mindoro </b>
                    </p>
                    <br>
                    <div style="text-align: center;">
                      ________________________
                      <br>
                      COA Representative
                      <br>
                      ______________________________
                      <br>
                      Authorized Representative
                    </div>
                  </div>
                  <div class="note">
                    <p style="color: red;"><b>NOTE: PLEASE DO NOT REMOVE</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

        </section>

        </main><!-- End #main -->

        <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>
  </template>

<script>
import axios from 'axios';
import jsQR from "jsqr";
import QRCode from 'qrcode-generator';
import html2pdf from 'html2pdf.js';

export default {
  data() {
    return {
      notifications: [],
      filter: 'all',
      info: [],
      employeeOptions: [], // Array to store employee names
      selectedEmployee: '', // Selected employee from dropdown
      selectedEmployeePDF: '', // Selected employee from dropdown
      selectedshowEmployeePDF: '',
      selectedClassification: '', // Selected classification from dropdown
      selectedArticle: '', // Selected article from dropdown
      selectedParticular: '', // Selected particular from dropdown
      selectedRemarks: '', // Selected status from dropdown
      video: null,
      resultElement: null,
      startButton: null,
      stopButton: null,
      isCameraOn: false,
      stream: null,
      qrCodeData: null,
      infos: [],
      selectedInfo: null,
      searchKeyword: '',
      currentPage: 1, // Current page number
      pageSize: 10, // Default page size
      previousEmployee: '', // Store the previous selected employee
      previousClassification: '', // Store the previous selected classification
      previousArticle: '', // Store the previous selected article
      previousParticular: '', // Store the previous selected particular
      loading: false,
      pdfUrl: '',
    }
  },
  mounted() {
    this.fetchNotifications();
  },
  created() {
    this.getInfo();
    this.user();
    this.getUserInfo(this.infos.fullname);
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
        try {
          const response = await axios.get('notification');
          this.notifications = response.data; // Set notifications to the fetched data
        } catch (error) {
          console.error(error);
        }
      },
      computeTimeAgo(dateString) {
        const now = Date.now(); // Current time in milliseconds
        const notificationDate = new Date(dateString).getTime(); // Convert dateString to milliseconds
        const secondsAgo = Math.floor((now - notificationDate) / 1000); // Difference in seconds

        if (secondsAgo < 60) return `${secondsAgo}s ago`;
        if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)}m ago`;
        if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)}h ago`;
        if (secondsAgo < 2592000) return `${Math.floor(secondsAgo / 86400)}d ago`;
        return `${Math.floor(secondsAgo / 2592000)}mo ago`;
      },
      truncateMessage(message) {
        return message.length > 70 ? message.substring(0, 67) + '...' : message;
      },
      filterNotifications(type) {
        this.filter = type;
      },

      async markAsRead(notificationId) {
        try {
          const response = await axios.post(`/markAsRead/${notificationId}`);
          console.log(response.data.msg); // Log the success message

          // Re-fetch notifications after marking one as read
          this.fetchNotifications();
        } catch (error) {
          console.error('Network error:', error.message);
        }
      },
      storeRecordId(recordId) {
        // Prompt the user for confirmation
        const isConfirmed = window.confirm(`Are you sure you want to open record?`);

        if (isConfirmed) {
            // Save the record ID to session storage
            sessionStorage.setItem('selectedRecordId', recordId);
            
            // Redirect to the new page
            this.$router.push({ path: '/databaseppedocs' });
        } else {
            // If the user cancels, you can handle it here if needed
            console.log('User canceled the action.');
        }
    },
    simulateLoading() {
    this.loading = true;
  },
      setTimeout() {
    this.loading = false;
  },
    selectRecord(info) {
      this.selectedInfo = info;
    },
    async downloadEmployeeRecordsPDF() {
      try {
          this.simulateLoading();

          const response = await fetch('https://inventrack.online/backend/employeeRecordsPDF', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ acc_officer: this.selectedEmployeePDF }),
          });

          if (response.ok) {
              const blob = await response.blob();
              const filename = `${this.selectedEmployeePDF}_records.pdf`;
              const url = window.URL.createObjectURL(blob);
              
              // Create a link element and trigger the download
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();

              // Cleanup
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
              console.log('PDF generated successfully');
          } else {
              console.error('Failed to generate PDF');
          }
          
          this.setTimeout();  // If this is meant to stop the loading animation
      } catch (error) {
          console.error('Error generating PDF:', error);
      }
  },


    async recordsPDF() {
    try {
        // Send HTTP request to backend to generate PDFs for all records
        this.simulateLoading();
        const response = await fetch('https://inventrack.online/backend/recordsPDF', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Check if the response is successful
        if (response.ok) {
            const blob = await response.blob();
            const filename = 'generated_pdf_all.pdf'; // Adjust the filename if needed
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            console.log('PDF generated successfully');
            this.setTimeout();
        } else {
            console.error('Failed to generate PDF');
        }
    } catch (error) {
        console.error('Error generating PDFs:', error);
    }
},
  updatePdfUrl() {
      if (this.selectedshowEmployeePDF) {
        this.pdfUrl = `https://inventrack.online/backend/showemployeerecordPDF/${this.selectedshowEmployeePDF}`;
      } else {
        this.pdfUrl = '';
      }
    },






async getUserInfo(id){
              try {
                  const inf = await axios.get(`getDataUser?id=${id}`);
                  this.info = inf.data;
              } catch (error) {
                  console.log(error);
              }
          },

      async user(){
        try{
          const id= sessionStorage.getItem("token")
          const response = await axios.get(`/users/${id}`, {
            id:id
          })
          this.infos = response.data;

        }catch(error){
          console.log(error);
        }
      },
      getImageStyle(imageUrl) {
      // Function to generate the background image style
        if (!imageUrl) {
          return {}; // Return an empty object if imageUrl is not provided
        }
        
        // Set the background image URL
        const backgroundImage = `url('https://inventrack.online/backend/uploads/${imageUrl}')`;
        
        // Set background size and position
        const backgroundSize = 'cover'; // Cover the entire container
        const backgroundPosition = '50% 50%'; // Center the image
        
        // Return the style object
        return {
          width: '100%',
          height: '100%',
          backgroundImage,
          backgroundSize,
          backgroundPosition,
          borderRadius: '50%' // Make the background circular
        };
      },

      generateQRCodeUrl(id) {
          return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${id}`;
        },


    deleteRecord(id) {
      // Implement your delete logic here
    },
    async getInfo() {
      try {
        const response = await axios.get('getDataWithImageVerification');
        this.info = response.data;
        // Extract employee names from info and store in employeeOptions
        this.employeeOptions = this.info.map(info => info.acc_officer);
      } catch (error) {
        console.log(error);
      }
    },
    async logout(){
          sessionStorage.removeItem('token');
          this.$router.push('/signin');
      },
    updatePageSize(size) {
      this.pageSize = size;
      this.getInfo();
    },
    onEmployeeChange() {
      // Reset selected values of dropdowns only if a new employee is selected
      if (this.selectedEmployee !== this.previousEmployee) {
        this.selectedClassification = '';
        this.selectedArticle = '';
        this.selectedParticular = '';
      }
      // Update the previous selected employee
      this.previousEmployee = this.selectedEmployee;
    },
  },

  computed: {
    filteredNotifications() {
      if (this.filter === 'unread') {
        return this.notifications.filter(notification => notification.status === 'unread');
      }
      return this.notifications;
    },
    unreadCount() {
      return this.notifications.filter(notification => notification.status === 'unread').length;
    },
    filteredInfo() {
      let filteredData = this.info;

      // Filter based on selected employee
      if (this.selectedEmployee !== '') {
        filteredData = filteredData.filter(info => info.acc_officer.toLowerCase() === this.selectedEmployee.toLowerCase());
      }

      // Filter based on selected classification
      if (this.selectedClassification !== '') {
        filteredData = filteredData.filter(info => info.classification.toLowerCase() === this.selectedClassification.toLowerCase());
      }

      // Filter based on selected article
      if (this.selectedArticle !== '') {
        filteredData = filteredData.filter(info => info.article.toLowerCase() === this.selectedArticle.toLowerCase());
      }

      // Filter based on selected particular
      if (this.selectedParticular !== '') {
        filteredData = filteredData.filter(info => info.particulars.toLowerCase() === this.selectedParticular.toLowerCase());
      }

      if (this.selectedRemarks !== '') {
    filteredData = filteredData.filter(info => info.remarks.toLowerCase() === this.selectedRemarks.toLowerCase());
  }

      // Filter based on search keyword
      if (this.searchKeyword !== '') {
        const keyword = this.searchKeyword.toLowerCase();
        filteredData = filteredData.filter(info => 
          (info.entityname && info.entityname.toLowerCase().includes(keyword)) ||
          (info.acc_officer && info.acc_officer.toLowerCase().includes(keyword)) ||
          (info.classification && info.classification.toLowerCase().includes(keyword)) ||
          (info.article && info.article.toLowerCase().includes(keyword)) ||
          (info.code && info.code.toLowerCase().includes(keyword)) ||
          (info.created_at && info.created_at.toLowerCase().includes(keyword)) ||
          (info.remarks && info.remarks.toLowerCase().includes(keyword)) ||
          (info.particulars && info.particulars.toLowerCase().includes(keyword))
        );
      }

      return filteredData;
    },

    paginatedInfo() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredInfo.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredInfo.length / this.pageSize);
    },
    currentPageRecords() {
      const startIndex = (this.currentPage - 1) * this.pageSize + 1;
      const endIndex = Math.min(startIndex + this.pageSize - 1, this.filteredInfo.length);
      return `Showing ${startIndex} - ${endIndex} of ${this.filteredInfo.length} records`;
    },
    
    distinctEmployees() {
      const employeeSet = new Set();
      this.info.forEach(item => employeeSet.add(item.acc_officer));
      return Array.from(employeeSet);
    },
    distinctClassification() {
      const classificationSet = new Set();
      this.info.forEach(item => {
        if (item.acc_officer && typeof item.acc_officer === 'string' &&
            item.acc_officer.toLowerCase() === this.selectedEmployee.toLowerCase() &&
            item.classification && typeof item.classification === 'string') {
          classificationSet.add(item.classification);
        }
      });
      return Array.from(classificationSet);
    },
    distinctArticle() {
      const articleSet = new Set();
      this.info.forEach(item => {
        if (item.acc_officer && typeof item.acc_officer === 'string' &&
            item.acc_officer.toLowerCase() === this.selectedEmployee.toLowerCase() &&
            item.classification && typeof item.classification === 'string' &&
            item.classification.toLowerCase() === this.selectedClassification.toLowerCase() &&
            item.article && typeof item.article === 'string') {
          articleSet.add(item.article);
        }
      });
      return Array.from(articleSet);
    },
    distinctParticular() {
      const particularSet = new Set();
      this.info.forEach(item => {
        if (item.acc_officer &&
            item.acc_officer.toLowerCase() === this.selectedEmployee.toLowerCase() &&
            item.classification &&
            item.classification.toLowerCase() === this.selectedClassification.toLowerCase() &&
            item.article &&
            item.article.toLowerCase() === this.selectedArticle.toLowerCase() &&
            item.particulars &&
            typeof item.particulars === 'string') {
          particularSet.add(item.particulars);
        }
      });
      return Array.from(particularSet);
    },
    distinctRemarks() {
    const remarksSet = new Set();
    this.info.forEach(item => {
      if (item.remarks) {
        remarksSet.add(item.remarks);
      }
    });
    return Array.from(remarksSet);
  },
}
}
</script>

<style scoped>
.page-link {
  z-index: 0;
}

#qr-video {
  width: 500px;
  height: 500px;
}
#qr-result {
  margin-top: 10px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Add margin to separate from the table */
}

.search-bar {
  width: 20%; /* Adjust width as needed */
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
}

.search-bar:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px #66afe9;
}

.pagination .page-item {
  display: inline-block;
  margin-right: 5px; /* Paggalang sa espasyo sa pagitan ng mga button */
}

.pagination .page-link {
  border: 1px solid #ced4da; /* Bawasan ang lapad ng border */
  color: #343a40; /* Itim na kulay ng text */
  border-radius: 0; /* Alisin ang radius ng border */
}

.pagination .page-link:hover {
  background-color: transparent; /* Alisin ang background color sa hover */
}

.pagination .page-item.disabled .page-link {
  pointer-events: none; /* Huwag pahintulutan ang pag-click kung ang button ay hindi aktibo */
}

.pagination .page-item.active .page-link {
  background-color: transparent; /* Alisin ang background color ng active button */
  border-color: #ced4da; /* Bawasan ang lapad ng border ng active button */
}

.pagination .page-link:focus {
  box-shadow: none; /* Alisin ang focus border */
}

.pagination .page-link.prev, .pagination .page-link.next {
  padding: 0; /* Alisin ang padding */
  border: none; /* Alisin ang border */
  background: none; /* Alisin ang background */
  font-size: 1.5rem; /* Taasan ang laki ng font */
  color: #343a40; /* Itim na kulay ng text */
}

.pagination .page-link.prev::after, .pagination .page-link.next::after {
  content: '\2190'; /* Isama ang Unicode character para sa arrow (left arrow) */
}

.pagination .page-link.next::after {
  content: '\2192'; /* Isama ang Unicode character para sa arrow (right arrow) */
}

.InputContainer {
  width: 200px; /* Increased width */
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgb(227, 213, 255), rgb(255, 231, 231));
  border-radius: 27px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.075);
}

.input {
  width: 190px; /* Increased width */
  height: 35px;
  border: none;
  outline: none;
  caret-color: rgb(255, 81, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 27px;
  padding-left: 12px;
  letter-spacing: 0.8px;
  color: rgb(19, 19, 19);
  font-size: 13.8px;
}


.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(148, 203, 255, 0.5);
  animation-name: modal-animation;
  animation-duration: 0.5s;
}

.modal-content {
  background-color: #ffffff;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%; /* Adjust the width to fit smaller screens */
  max-width: 600px; /* Max width to ensure readability on larger screens */
  animation-name: modal-content-animation;
  animation-duration: 0.5s;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@keyframes modal-animation {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes modal-content-animation {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 768px) { /* Adjust for smaller screens */
  .modal-content {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
  }
}

.loading-line {
  width: 100%;
  height: 4px;
  background-color: #ffffff; /* Background color of the loading container */
  position: relative;
  overflow: hidden; /* Ensures the loading line stays within the container */
}

.loading-line::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: rgb(0, 68, 255); /* Color of the loading line */
  animation: loading 0.5s linear infinite; /* Faster looping animation */
}

@keyframes loading {
  0% {
    transform: translateX(-100%); /* Start from the left */
  }
  100% {
    transform: translateX(100%); /* Move to the right */
  }
}


.property-info {
  margin-bottom: 20px;
}

.property-info p {
  margin: 0;
}
.details {
  margin-bottom: 20px;
}

.details p {
  margin: 0;
}
.note {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px; /* Adjust font size as needed */
}

th, td {
  border: 1px solid #dee2e6;
  padding: 4px 9px; /* Adjust padding for better readability */
  text-align: center;
  white-space: nowrap; /* Prevent line breaks in cells */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Show ellipsis for overflowed content */
}

th {
  height: 27px; /* Adjust height for header */
  white-space: nowrap;
}

/* Optional: Add a wrapper for horizontal scrolling */
.wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on mobile */
}


    /* Responsive styles */
    @media screen and (max-width: 600px) {
      table, tr, td {
        display: block;
      }

      td {
        border: none;
        position: relative;
      }

      td::before {
        content: attr(data-label);
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      /* Make the table scrollable on smaller screens */
      table {
        overflow-y: auto;
      }
    }


</style>





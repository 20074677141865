<template>
  <div id="app" style="background-image: url('./img/color.jpg'); background-size: cover; background-attachment: fixed; height: 100%;">
        <!-- ======= Header ======= -->
        <header id="header" class="header fixed-top d-flex align-items-center">
  
          <div class="d-flex align-items-center justify-content-between">
            <a href="/dashboard" class="logo d-flex align-items-center" style="position: relative;">
              <img src="./img/dilg-logo1.png" alt="" 
                   style="position: absolute; max-height: 220px; max-width: 220px; margin-left: -30px; z-index: 1;">
              <span style="font-family: 'Times New Roman', Times, serif; font-size: 25px; color: rgb(42, 43, 72); padding-left: 120px; z-index: 2; position: relative;">
                INVENTrack
              </span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn"></i>
          </div><!-- End Logo -->
          
  
      <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
  
          <!-- Notification Icon -->
          <li class="nav-item dropdown">
            <a class="nav-link nav-icon" href="#" @click="fetchNotifications" data-bs-toggle="dropdown">
              <i class="bi bi-bell"></i>
              <span class="badge bg-danger badge-number">{{ unreadCount }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" @click.stop>
              <!-- Title and Tabs -->
              <li class="dropdown-header">
                <span class="notifications-title">Notifications</span>
                <nav class="notifications-nav">
                  <button @click="filterNotifications('all')" :class="{ active: filter === 'all' }">All</button>
                  <button @click="filterNotifications('unread')" :class="{ active: filter === 'unread' }">Unread</button>
                </nav>
              </li>
              <hr />

              <!-- Notifications List -->
              <li
              v-for="notification in filteredNotifications"
              :key="notification.id"
              :class="['dropdown-item', notification.status === 'unread' ? 'notification-unread' : 'notification-read']"
              @click="markAsRead(notification.id)"
              >
                <div class="notification-content">
                  <!-- Icon in a white circle -->
                  <div class="notification-icon-circle">
                    <i :class="notification.icon"></i> <!-- Icon from the database -->
                  </div>
              
                  <!-- Message and Time -->
                  <div class="notification-details">
                    <span class="notification-message">{{ truncateMessage(notification.message) }}</span>
                    <span class="notification-time">{{ computeTimeAgo(notification.created_at) }}</span> <!-- Time below the message -->
                  </div>
              
                  <!-- Unread Indicator Circle -->
                  <span class="notification-indicator" v-if="notification.status === 'unread'"></span>
                </div>
              </li>
            
              <li v-if="filteredNotifications.length === 0" class="dropdown-item text-center">No notifications</li>
            </ul>
          </li>

  
          <li class="nav-item dropdown pe-3">
  
            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
              <div style="width: 50px; height: 50px; overflow: hidden; border-radius: 50%;">
                <div :style="getImageStyle(infos.image)"></div>
              </div>
              <span class="d-none d-md-block dropdown-toggle ps-2">{{ infos.fullname }}</span>
            </a><!-- End Profile Image Icon -->
  
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li class="dropdown-header">
                <h6>{{ infos.fullname }}</h6>
                <span>{{ infos.position }}</span>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                  <i class="bi bi-person"></i>
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                  <i class="bi bi-gear"></i>
                  <span>Account Settings</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                  <i class="bi bi-question-circle"></i>
                  <span>Need Help?</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
  
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)" @click="logout">
                  <i class="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
  
            </ul><!-- End Profile Dropdown Items -->
          </li><!-- End Profile Nav -->
  
        </ul>
      </nav><!-- End Icons Navigation -->
  
      </header><!-- End Header -->
  
  
  
  
  
  
  
  
      <!-- ======= Sidebar ======= -->
      <aside id="sidebar" class="sidebar">
  
        <ul class="sidebar-nav" id="sidebar-nav">
    
          
          <li class="nav-heading">Home</li>
    
          <li class="nav-item">
            <a class="nav-link collapsed" href="/dashboard">
              <i class="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li><!-- End Dashboard Nav -->
    
          
          <!-- Pages Section -->
          <li class="nav-heading">Pages</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="databaseppe">
              <i class="bi bi-clipboard-data"></i>
              <span>Database PPE</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
              <i class="bi bi-menu-button-wide"></i><span>PROPERTY, PLANT AND EQUIPMENT</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li>
                <a href="/serviceable">
                  <i class="bi bi-clipboard-check"></i><span>Serviceable</span>
                </a>
              </li>
              <li>
                <a href="unserviceable">
                  <i class="bi bi-clipboard-x"></i><span>Unserviceable</span>
                </a>
              </li>
              <li>
                <a href="returnedppe">
                  <i class="bi bi-box-arrow-left"></i><span>Returned PPE</span>
                </a>
              </li>
              <li>
                <a href="transferedppe">
                  <i class="bi bi-box-arrow-right"></i><span>Transfered PPE</span>
                </a>
            </li>
            <li>
                <a href="disposedppe">
                  <i class="bi bi-trash"></i><span>Disposed PPE</span>
                </a>
            </li>
            </ul>
          </li><!-- End Components Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
              <i class="bi bi-journal-text"></i><span>Documents</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li>
                <a href="propertysticker">
                  <i class="bi bi-sticky"></i><span>Property Sticker</span>
                </a>
              </li>
              <li>
                <a href="ledgercard">
                  <i class="bi bi-folder2-open"></i><span>PPE Documents</span>
                </a>
              </li>
            </ul>
          </li><!-- End Forms Nav -->
          <!-- Input Section -->
          <li class="nav-heading">input</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/workspace">
              <i class="bi bi-pencil-square"></i>
              <span>Workspace</span>
            </a>
          </li><!-- End Input Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" href="/logbook">
              <i class="bi bi-calendar-check"></i>
              <span>Logbook</span>
            </a>
          </li><!-- End Input Nav -->
          <!-- Stocks Section -->
          <li class="nav-heading">Stocks</li>
          <li class="nav-item">
            <a class="nav-link active" href="/inventory">
              <i class="bi bi-box-seam"></i>
              <span>Inventory</span>
            </a>
          </li><!-- End Stocks Nav -->
          <li class="nav-item">
            <a class="nav-link collapsed" href="/supplies">
              <i class="bi bi-stack"></i>
              <span>Supplies</span>
            </a>
          </li>
          <!-- Ordering Section -->
          <li class="nav-heading">Ordering</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/ordering">
              <i class="bi bi-shop"></i>
              <span>Ordering</span>
            </a>
          </li><!-- End Ordering Nav -->
          <!-- Security Section -->
          <li class="nav-heading">Security</li>
          <li class="nav-item">
            <a class="nav-link collapsed" href="/userverify">
              <i class="bi bi-person-check"></i>
              <span>User Verification</span>
            </a>
          </li><!-- End Security Nav -->
        </ul>
      </aside><!-- End Sidebar-->
  
  
  
  
  
  
  
      <main id="main" class="main">
  
      <div class="pagetitle">
        <h1>Inventory</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inventory">Stocks</a></li>
            <li class="breadcrumb-item active">Inventory</li>
          </ol>
        </nav>
      </div><!-- End Page Title -->
    
        <!-- Section for the Stock Table -->
      <section class="section">
        <div class="row">
          <div class="col-lg-12" style="font-size: medium;">

            <div class="card">
              <div class="card-body" style="border-left: 30px solid #80faf4;">
        
      <br>
      <!-- Add Item Button -->
      <div class="button-container">
        <button @click="showAddItemModal = true" class="noselect">
          <span class="text">Add Item</span>
          <span class="icon">
            <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"></svg>
            <span class="buttonSpan">+</span>
          </span>
        </button>
      </div>
      <br>

      <!-- Search Bar and Show Entries Dropdown -->
      <div class="d-flex justify-content-between align-items-center container-inventory" >
        <!-- Show Entries Dropdown -->
        <div class="d-flex align-items-center">
          <span class="me-2">Show</span>
          <div class="dropdown" style="display: inline-block;">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="showEntriesDropdown" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: white; color: black;">
              {{ pageSize }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="showEntriesDropdown">
              <li><a class="dropdown-item" href="#" @click="updatePageSize(10)">10</a></li>
              <li><a class="dropdown-item" href="#" @click="updatePageSize(20)">20</a></li>
              <li><a class="dropdown-item" href="#" @click="updatePageSize(30)">30</a></li>
              <!-- Add more options as needed -->
            </ul>
          </div>
          <span class="ms-2">entries</span>
        </div>

        <!-- Centered Dropdowns -->
        <div class="filters-container d-flex align-items-center">
          <!-- Filters: Select Classification, Article, Particulars, Current Status -->
          <select v-model="selectedClassification" class="form-select me-2" @change="handleClassificationChange">
            <option value="">Select Classification</option>
            <option v-for="classification in distinctClassification" :key="classification" :value="classification">{{ classification }}</option>
          </select>

          <select v-model="selectedArticle" class="form-select me-2" :disabled="!selectedClassification" @change="handleArticleChange">
            <option value="">Select Article</option>
            <option v-for="article in filteredArticles" :key="article" :value="article">{{ article }}</option>
          </select>

          <select v-model="selectedParticular" class="form-select me-2" :disabled="!selectedArticle">
            <option value="">Select Particular</option>
            <option v-for="particular in filteredParticulars" :key="particular" :value="particular">{{ particular }}</option>
          </select>

          <select v-model="selectedStatus" class="form-select">
            <option value="">Current Status</option>
            <option v-for="status in distinctStatus" :key="status" :value="status">{{ status }}</option>
          </select>
        </div>

        <!-- Search Bar -->
        <div class="InputContainer">
          <input placeholder="Search..." id="input" class="input" name="text" type="text" v-model="searchQuery">
        </div>
      </div>


      <!-- Backdrop for Darkening Effect -->
      <div v-if="showAddItemModal" class="modal-backdrop"></div>

      <!-- Add Item Modal -->
      <transition name="modal-slide">
        <div v-if="showAddItemModal" class="modal fade show d-block" tabindex="-1" role="dialog" aria-labelledby="addItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addItemModalLabel">Add Item</h5>
                        <button type="button" class="btn-close" @click="showAddItemModal = false" aria-label="Close"></button>
                    </div>
                      <div class="modal-body">
                        <form class="row g-3" enctype="multipart/form-data" @submit.prevent="saveOrUpdate" >
                          <!-- Form fields here -->
                          <div class="col-md-6">
                            <label for="entityname" class="form-label">Entity Name</label>
                            <input type="text" class="form-control" id="entityname" v-model="entityname" required>
                          </div>
                          <div class="col-md-6">
                            <label for="classification" class="form-label">Classification</label>
                            <input type="text" class="form-control" id="classification" v-model="classification" required>
                          </div>
                          <div class="col-md-6">
                            <label for="code" class="form-label">Code</label>
                            <input type="text" class="form-control" id="code" v-model="code" required>
                          </div>
                          <div class="col-md-6">
                            <label for="article" class="form-label">Article</label>
                            <input type="text" class="form-control" id="article" v-model="article" required>
                          </div>
                          <div class="col-md-6">
                            <label for="particulars" class="form-label">Particulars</label>
                            <input type="text" class="form-control" id="particulars" v-model="particulars" required>
                          </div>
                          <div class="col-md-6">
                            <label for="modelno" class="form-label">Model No.</label>
                            <input type="text" class="form-control" id="modelno" v-model="modelno">
                          </div>
                          <div class="col-md-6">
                            <label for="serialno" class="form-label">Serial No.</label>
                            <input type="text" class="form-control" id="serialno" v-model="serialno">
                          </div>
                          <div class="col-md-6">
                            <label for="propertynumber" class="form-label">Semi Expendable Property No.</label>
                            <input type="text" class="form-control" id="propertynumber" v-model="propertynumber">
                          </div>
                          <div class="col-md-6">
                            <label for="propertydate" class="form-label">Date Arrived:</label>
                            <input type="date" class="form-control" id="propertydate" v-model="propertydate">
                          </div>
                          <div class="col-md-6">
                            <label for="quantity" class="form-label">Quantity</label>
                            <input type="text" class="form-control" id="quantity" v-model="quantity" required placeholder="0">
                          </div>
                          <div class="col-md-6">
                            <label for="unit" class="form-label">Unit</label>
                            <select class="form-select" id="unit" v-model="unit" required>
                              <option value="unit">Unit</option>
                              <option value="set">Set</option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="unitcost" class="form-label">Unit Cost</label>
                            <input type="text" class="form-control" id="unitcost" v-model="unitcost" required placeholder="0">
                          </div>
                          <div class="col-md-6">
                            <label for="totalcost" class="form-label">Total Cost</label>
                            <input type="text" class="form-control" id="totalcost" :value="totalCostFormatted" required disabled placeholder="0">
                          </div>
                          <div class="row">
                            <!-- Left Column: Upload and Capture -->
                            <div class="col-md-6">
                              <label class="form-label"><strong>Choose Image Source:</strong></label>
                              <div class="image-source-options d-flex justify-content-around">
                                <input type="radio" id="upload" value="upload" v-model="imageSource" class="radio-input">
                                <label for="upload" class="radio-label">Upload Image</label>
                          
                                <input type="radio" id="capture" value="capture" v-model="imageSource" class="radio-input">
                                <label for="capture" class="radio-label">Capture Image</label>
                              </div>
                          
                              <!-- File Upload Section -->
                              <div v-if="imageSource === 'upload'" class="mt-3">
                                <label for="image" class="form-label"><strong>Upload Image</strong></label>
                                <input type="file" class="form-control file-input" id="image" @change="handleFileUpload" accept="image/*">
                              </div>
                          
                              <!-- Camera Capture Section -->
                              <div v-else-if="imageSource === 'capture'" class="mt-3">
                                <label for="camera" class="form-label"><strong>Capture Image</strong></label>
                                <div class="video-container">
                                  <video id="camera" width="100%" height="auto" autoplay></video>
                                  <div class="camera-controls mt-2">
                                    <button type="button" @click="startCamera" class="btn btn-primary">
                                      {{ cameraStarted ? 'Stop Camera' : 'Start Camera' }}
                                    </button>
                                    <button type="button" @click="captureImage" class="btn btn-success" :disabled="!cameraStarted">
                                      Capture
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                            <!-- Right Column: Preview Section -->
                            <div class="col-md-6 text-center">
                              <label class="form-label"><strong>Image Preview:</strong></label>
                              <div class="preview-container mt-2">
                                <img :src="imagePreview" v-if="imagePreview" alt="Image Preview" class="img-fluid image-preview">
                              </div>
                            </div>
                          </div>
                          
                          
                <br>
                <hr>
                          <div class="button-group mt-6">
                
                            <button class="button1" @click="resetForm" type="reset" style="width: 120px; height: 40px;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"></path>
                  </svg>
                  Reset
                </button>
                  <button v-if="status !== 'update'" type="submit" class="button">Submit</button>
                  <button v-if="status === 'update'" type="submit" class="button">Update</button>
                
                
                </div>
      
              </form>
            </div>
          </div>
        </div>
      </div>
      </transition>

      <br>
      <br>
              

      <div class="wrapper">
        <div class="table-container" style="overflow-x: auto; max-height: 500px;">
          <table class="table-compact">
            <qrcode-stream @decode="onDecode" />
          
            <thead style="width: 100%;">
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Entity</th>
                <th scope="col">Classification</th>
                <th scope="col">Code</th>
                <th scope="col">Article</th>
                <th scope="col">Particulars</th>
                <th scope="col">Model No.</th>
                <th scope="col">Serial No.</th>
                <th scope="col">Full Description</th>
                <th scope="col">Qty</th>
                <th scope="col">Unit</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Total Cost</th>
                <th scope="col">Status</th>
                <th scope="col" class="sticky-col">Availability</th>
                <th scope="col" class="sticky-col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inv in paginatedInfo.filter(item => item.status === 'active')">
                <td scope="row">
                  <img :src="`https://inventrack.online/backend/uploads/${inv.image}`" alt="Inventory Image" style="max-width: 60px; max-height: 60px;" />
                </td>
                <td scope="row">{{ inv.entityname }}</td>
                <td scope="row">{{ inv.classification }}</td>
                <td scope="row">{{ inv.code }}</td>
                <td scope="row">{{ inv.article }}</td>
                <td scope="row">{{ inv.particulars }}</td>
                <td scope="row">{{ inv.modelno }}</td>
                <td scope="row">{{ inv.serialno }}</td>
                <td scope="row">{{ inv.fulldescription }}</td>
                <td scope="row">{{ inv.quantity }}</td>
                <td scope="row">{{ inv.unit }}</td>
                <td scope="row">{{ inv.unitcost }}</td>
                <td scope="row">{{ inv.totalcost }}</td>
                <td scope="row">{{ inv.status }}</td>
                <td scope="row" class="sticky-col">
                  <button v-if="inv.availability === 'yes'" @click="updateAvailability(inv.id, 'no')" class="link btn btn-outline-success" style="width: 120px">Available</button>
                  <button v-else-if="inv.availability === 'no' || inv.availability === ''" @click="updateAvailability(inv.id, 'yes')" class="btn btn-outline-danger">Not Available</button>
                </td>
                <td class="sticky-col">
                  <button class="btn btn-outline-success" @click="selectRecord(inv)"><i class="bx ri-file-list-line"></i></button>
                  <button @click="placeRecord(inv.id)" class="btn btn-warning"><i class="bx bxs-arrow-from-right"></i></button>
                  <button @click="deleteRecord(inv.id)" class="btn btn-danger"><i class="ri-delete-bin-6-line"></i></button>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-for="inv in paginatedInfo.filter(item => item.status === 'inactive')" :class="{ 'inactive-row': inv.status === 'inactive' }">
                <td scope="row">
                  <img :src="`https://inventrack.online/backend/uploads/${inv.image}`" alt="Inventory Image" style="max-width: 60px; max-height: 60px;" />
                </td>
                <td scope="row">{{ inv.entityname }}</td>
                <td scope="row">{{ inv.classification }}</td>
                <td scope="row">{{ inv.code }}</td>
                <td scope="row">{{ inv.article }}</td>
                <td scope="row">{{ inv.particulars }}</td>
                <td scope="row">{{ inv.modelno }}</td>
                <td scope="row">{{ inv.serialno }}</td>
                <td scope="row">{{ inv.fulldescription }}</td>
                <td scope="row">{{ inv.quantity }}</td>
                <td scope="row">{{ inv.unit }}</td>
                <td scope="row">{{ inv.unitcost }}</td>
                <td scope="row">{{ inv.totalcost }}</td>
                <td scope="row">{{ inv.status }}</td>
                <td scope="row" class="sticky-col">
                  <button v-if="inv.availability === 'yes'" @click="updateAvailability(inv.id, 'no')" class="link btn btn-outline-success" style="width: 120px">Available</button>
                  <button v-else-if="inv.availability === 'no' || inv.availability === ''" @click="updateAvailability(inv.id, 'yes')" class="btn btn-outline-danger">Not Available</button>
                </td>
                <td class="sticky-col">
                  <button class="btn btn-outline-success" @click="selectRecord(inv)"><i class="bx ri-file-list-line"></i></button>
                  <button @click="placeRecord(inv.id)" class="btn btn-warning"><i class="bx bxs-arrow-from-right"></i></button>
                  <button @click="deleteRecord(inv.id)" class="btn btn-danger"><i class="ri-delete-bin-6-line"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      <br>
      <br>
        <!-- I-update ang table at idagdag ang pagination controls -->
        <div class="card-body">
        <!-- Iba pang content ng card... -->
        <div class="text-center">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center mb-0">
            <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="currentPage = Math.max(currentPage - 1, 1)">Previous</a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ 'active': currentPage === page }">
              <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="currentPage = Math.min(currentPage + 1, totalPages)">Next</a>
            </li>
          </ul>
        </nav>
        <br>
        <div class="page-records">
        <p>{{ currentPageRecords }}</p>
      </div>

      </div>
      </div>

    

          </div>
        </div>
      </div>
    </div>
  </div>

<!-- Modal component to display selected record -->
<div class="modal-backdrop" v-if="selectedInfo">
  <div class="modal fade show d-block">
    <div class="modal-dialog modal-xl modal-dialog" role="document">
      <div class="modal-content" style="
            font-family: Calibri;
            font-size: 14px;
            line-height: 1.5;
            color: #333;
            background-color: #f9f9f9;
            padding: 20px;">
        <span class="close" @click="selectedInfo = null">&times;</span>

        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
                <br>
                <h3><b>Generate QR Code for Workspace</b></h3>
                <!-- QR Code container with a fixed size -->
                <div class="qr-code-container">
                  <!-- <img class="qr-img" v-if="qrCodeVisible" :src="qrCodeUrl" alt="QR Code" /> -->
                  <QRCodeGenerator class="qr-img" v-if="qrCodeVisible" :data="qrCodeData" :logoUrl="logoImageUrl" />
                </div>
                <button @click="toggleQrCode" class="btn btn-primary mt-3">
                  {{ qrCodeVisible ? 'Close' : 'Generate QR Code' }}
                </button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

</section>

  
      </main><!-- End #main -->
  
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>
  </template>
  
  
  <script>
  
  // Components
  import axios from 'axios'
  import QRCodeGenerator from "../../components/QRCodeGenerator.vue";
  
  export default{
    components: {
        QRCodeGenerator,
      },
  
  computed:{
    filteredNotifications() {
      if (this.filter === 'unread') {
        return this.notifications.filter(notification => notification.status === 'unread');
      }
      return this.notifications;
    },
    unreadCount() {
      return this.notifications.filter(notification => notification.status === 'unread').length;
    },
    imagePreview() {
          if (this.imageSource === 'upload') {
              return this.uploadedImage;
          } else if (this.imageSource === 'capture') {
              return this.capturedImage;
          } else {
              return null; // or a default placeholder image
          }
      },
      totalCost() {
            return this.quantity * this.unitcost;
          },
      totalCostFormatted() {
            // Format total Cost with commas
            return this.totalCost.toLocaleString();
          },

          paginatedInfo() {
          const startIndex = (this.currentPage - 1) * this.pageSize;
          const endIndex = startIndex + this.pageSize;
          return this.filteredInventory.slice(startIndex, endIndex);
        },
        totalPages() {
          return Math.ceil(this.filteredInventory.length / this.pageSize);
        },
        currentPageRecords() {
          const startIndex = (this.currentPage - 1) * this.pageSize + 1;
          const endIndex = Math.min(startIndex + this.pageSize - 1, this.filteredInventory.length);
          return `Showing ${startIndex} - ${endIndex} of ${this.filteredInventory.length} records`;
        },
  
      distinctClassification() {
        return [...new Set(this.inventory.map(inv => inv.classification))];
      },
      filteredArticles() {
        return [...new Set(this.inventory.filter(inv => inv.classification === this.selectedClassification).map(inv => inv.article))];
      },
      filteredParticulars() {
        return [...new Set(this.inventory.filter(inv => inv.article === this.selectedArticle).map(inv => inv.particulars))];
      },
      distinctStatus() {
        return [...new Set(this.inventory.map(inv => inv.status))];
      },
      filteredInventory() {
        let filtered = this.inventory;
        if (this.selectedClassification) {
          filtered = filtered.filter(inv => inv.classification === this.selectedClassification);
        }
        if (this.selectedArticle) {
          filtered = filtered.filter(inv => inv.article === this.selectedArticle);
        }
        if (this.selectedParticular) {
          filtered = filtered.filter(inv => inv.particulars === this.selectedParticular);
        }
        if (this.selectedStatus) {
          filtered = filtered.filter(inv => inv.status === this.selectedStatus);
        }
        return filtered.filter(inv => {
          return (
            inv.entityname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.classification.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.article.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.particulars.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.modelno.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.serialno.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.fulldescription.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.propertynumber.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            // inv.propertydate.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.quantity.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.unit.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.unitcost.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.totalcost.toString().toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            inv.status.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      },
  
  },
  data(){
      return{
          notifications: [],
          filter: 'all',
          showAddItemModal: false,  // Para ipakita o itago ang Add Item modal
          id: "",
          inventory:[],
          info:[],
          infos:[],
          entityname: "",
          classification: "",
          code: "",
          article: "",
          particulars: "",
          modelno: "",
          serialno: "",
          fulldescription: "",
          propertynumber: "",
          propertydate: "",
          unit: "",
          unit: "",
          unitcost: "",
          totalcost: "",
          arrival: "",
          image: null,
          status: "",
          statusId: "",
          mediaStream: null,
          cameraStarted: false,
          capturedImage: null,
          imageDataUrl: "",
          cameraButtonText: 'Start Camera',
          selectedImageFile: null,
          imageSource: 'upload',
          imagePreview: '',
          searchQuery: '',
          selectedInfo: null,
          selectedClassification: '',
          selectedArticle: '',
          selectedParticular: '',
          selectedStatus: '',
          currentPage: 1, // Current page number
          pageSize: 10, // Default page size
          qrCodeVisible: false, // Tracks if QR code is displayed
          qrCodeUrl: '', // Holds the generated QR code URL
          qrCodeData: null, // Replace with your dynamic ID or data
          logoImageUrl: "./img/dilg-logo.png", // Local or external logo URL
  
      }
  },
  created(){
      this.getInventory()
      this.user();
      this.getUserInfo(this.infos.fullname);
      this.getInfo();
      this.fetchNotifications();
  },
  
  methods:{
    toggleQrCode() {
        if (this.qrCodeVisible) {
            // If QR code is visible, hide it
            this.qrCodeVisible = false;
            this.qrCodeData = '';
        } else {
            // If not visible, generate and show the QR code
            const id = this.selectedInfo.propertynumber; // Replace with dynamic data if necessary
            this.qrCodeData = id;
            this.qrCodeVisible = true;
        }
        },
    async fetchNotifications() {
        try {
          const response = await axios.get('notification');
          this.notifications = response.data; // Set notifications to the fetched data
        } catch (error) {
          console.error(error);
        }
      },
      computeTimeAgo(dateString) {
        const now = Date.now(); // Current time in milliseconds
        const notificationDate = new Date(dateString).getTime(); // Convert dateString to milliseconds
        const secondsAgo = Math.floor((now - notificationDate) / 1000); // Difference in seconds

        if (secondsAgo < 60) return `${secondsAgo}s ago`;
        if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)}m ago`;
        if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)}h ago`;
        if (secondsAgo < 2592000) return `${Math.floor(secondsAgo / 86400)}d ago`;
        return `${Math.floor(secondsAgo / 2592000)}mo ago`;
      },
      truncateMessage(message) {
        return message.length > 70 ? message.substring(0, 67) + '...' : message;
      },
      filterNotifications(type) {
        this.filter = type;
      },

      async markAsRead(notificationId) {
        try {
          const response = await axios.post(`/markAsRead/${notificationId}`);
          console.log(response.data.msg); // Log the success message

          // Re-fetch notifications after marking one as read
          this.fetchNotifications();
        } catch (error) {
          console.error('Network error:', error.message);
        }
      },
    selectRecord(inventory) {
      this.selectedInfo = inventory;
    },
    generateQRCodeUrl(id) {
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${id}`;
    },
    handleClassificationChange() {
        this.selectedArticle = '';
        this.selectedParticular = '';
      },
      handleArticleChange() {
        this.selectedParticular = '';
      },
    handleFileUpload(event) {
          const file = event.target.files[0];
          if (file) {
              this.uploadedImage = URL.createObjectURL(file);
              this.selectedImageFile = file;
              this.imagePreview = this.uploadedImage;
          }
      },
      updatePageSize(size) {
    this.pageSize = size;
    this.currentPage = 1; // Reset to first page
  },
    
    async startCamera() {
      const videoElement = document.getElementById('camera');
      if (!this.cameraStarted) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoElement.srcObject = stream;
            this.mediaStream = stream;
            this.cameraStarted = true;
            this.cameraButtonText = 'Stop Camera';
          } catch (error) {
            console.error('Error accessing camera:', error);
          }
        } else {
          console.error('getUserMedia is not supported');
        }
      } else {
        // Stop the camera
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => {
            track.stop();
          });
        }
        this.cameraStarted = false;
        this.cameraButtonText = 'Start Camera';
      }
    },
      async captureImage() {
        if (!this.cameraStarted) {
          console.error('Camera not started yet');
          return;
        }
  
        const canvas = document.createElement('canvas');
        canvas.width = 320;
        canvas.height = 240;
        const context = canvas.getContext('2d');
        context.drawImage(document.getElementById('camera'), 0, 0, 320, 240);
        const imageDataUrl = canvas.toDataURL('image/png');
  
        // Set the captured image for preview
        this.capturedImage = imageDataUrl;
        this.imagePreview = this.capturedImage;
      },
  
      deleteRecord(id) {
        // Implement your delete logic here
      },
      /*async deleteRecord(recordId){
          const confirm = window.confirm("are you sure you want to delete this?");
          if (confirm){
              await axios.post("del", {
              id: recordId,
          });
          this.getInventory();
          }
      }, */
      async getInventory(){
          try {
              const inv = await axios.get('getInventory');
              this.inventory = inv.data;
              console.log(this.inventory);
          } catch (error) {
              console.log(error);
          }
      },
      async getInfo() {
      try {
        const response = await axios.get('getdata');
        this.info = response.data;
        // Extract employee names from info and store in employeeOptions
        this.employeeOptions = this.info.map(info => info.acc_officer);
      } catch (error) {
        console.log(error);
      }
    },
      async save() {
        try {
          // Compute totalcost
  
          // Create a FormData object and append the computed totalcost
          const formData = new FormData();
          if (this.selectedImageFile) {
              formData.append('image', this.selectedImageFile);
          }
          else if (this.capturedImage) {
              // Convert the captured image to a file
              const blob = await fetch(this.capturedImage).then(res => res.blob());
              const file = new File([blob], `image_${Date.now()}.png`, { type: 'image/png' });
              formData.append('image', file);
          }
  
          // Append other form data including the computed totalcost
          formData.append('entityname', this.entityname);
          formData.append('classification', this.classification);
          formData.append('code', this.code);
          formData.append('article', this.article);
          formData.append('particulars', this.particulars);
          formData.append('modelno', this.modelno);
          formData.append('serialno', this.serialno);
          formData.append('propertynumber', this.propertynumber);
          formData.append('propertydate', this.propertydate);
          formData.append('quantity', this.quantity);
          formData.append('unit', this.unit);
          formData.append('unitcost', this.unitcost);
          formData.append('totalcost', this.quantity * this.unitcost); // Ensure totalcost is included
  
          // Now you can submit the formData to your backend endpoint using Axios or any other method
          await axios.post('/saveInventory', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
  
          // Reset the form and emit the 'data-saved' event
          this.resetForm();
          this.status = ""; // reset status after update
          this.$emit('data-saved');
          this.getInventory();
  
        } catch (error) {
          this.resetForm();
          this.status = ""; // reset status after update
          this.$emit('data-saved');
          this.getInventory();
          console.error('Error saving:', error);
        }
      },
  
      dataURLtoFile(dataUrl) {
        const binary = atob(dataUrl.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new File([new Uint8Array(array)], 'image.png', { type: 'image/png' });
      },
      beforeDestroy() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    },
  
      // async save() {
      //   try {
      //     // Retrieve the selected image file
      //     const imageFile = this.$refs.image.files[0];
  
      //     // Use FormData to send both text and file data
      //     const formData = new FormData();
      //     formData.append('entityname', this.entityname);
      //     formData.append('particulars', this.particulars);
      //     formData.append('classification', this.classification);
      //     formData.append('quantity', this.quantity);
      //     formData.append('arrival', this.arrival);
      //     formData.append('image', imageFile);
  
      //     // Send data using axios.post with FormData
      //     const inv = await axios.post('saveInventory', formData, {
      //       headers: {
      //         'Content-Type': 'multipart/form-data',
      //       },
      //     });
  
      //     this.resetForm();
      //     this.$emit('data-saved');
      //     this.getInventory();
  
      //     await axios.post('/triggerNotification')
      //       .then(response => {
      //         console.log('Notification triggered successfully');
      //       })
      //       .catch(error => {
      //         console.error('Error triggering notification:', error);
      //       });
  
      //   } catch (error) {
      //     console.error('Error saving data:', error);
      //   }
      // },
  
      async saveOrUpdate() {
        if (this.status === "update") {
            await this.updateRecord();
        } else {
          await this.save();
        }
      },
  
      //     async updateRecord() {
  //     try {
  //         const data = {
  //             id: this.statusId,
  //             entityname: this.entityname,
  //             particulars: this.particulars,
  //             classification: this.classification,
  //             quantity: this.quantity,
  //             arrival: this.arrival,
  //         };
  
  //         const response = await axios.post(`/updateInventory/${this.statusId}`, data);
          
  //         if (response.data.status === 'success') {
  //             this.resetForm();
  //             this.status = ""; // reset status after update
  //             this.getInventory();
  //             console.log("Hi");
  //             console.log(this.statusId); // Accessing statusId from component's state
  //             console.log(data);
  //         } else {
  //             console.error("Failed to update record:", response.data.message);
  //         }
  //     } catch (error) {
  //         console.error("Error updating record:", error);
  //     }
  // },
  
  async updateRecord() {
      try {
          const formData = new FormData();
  
          // Append the updated data
          formData.append('id', this.statusId);
          formData.append('entityname', this.entityname);
          formData.append('classification', this.classification);
          formData.append('code', this.code);
          formData.append('article', this.article);
          formData.append('particulars', this.particulars);
          formData.append('modelno', this.modelno);
          formData.append('serialno', this.serialno);
          formData.append('propertynumber', this.propertynumber);
          formData.append('propertydate', this.propertydate);
          formData.append('quantity', this.quantity);
          formData.append('unit', this.unit);
          formData.append('unitcost', this.unitcost);

  
          // Always append the image field, even if it's not changed
          if (this.capturedImage) {
              const blob = await fetch(this.capturedImage).then(res => res.blob());
              const file = new File([blob], `image_${Date.now()}.png`, { type: 'image/png' });
              formData.append('image', file);
              alert("Error a");
          } else if (this.selectedImageFile) {
              formData.append('image', this.selectedImageFile);
              alert("Error b");
          } else {
              // If the image is not changed, you can append the existing image data
              formData.append('image', this.imagePreview);
              alert(this.imagePreview);
          }
  
          const response = await axios.post(`/updateInventory/${this.statusId}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
  
          if (response.data.status === 'success') {
              this.resetForm();
              this.status = ""; // reset status after update
              this.getInventory();
              console.log("Record updated successfully");
              alert("Success 1");
          } else {
              console.error("Failed to update record:", response.data.message);
              alert("Error 2");
          }
      } catch (error) {
          console.error("Error updating record:", error);
          alert("Error 3");
      }
  },
  
  placeRecord(recordId) {
      // set status to update and statusId to the record id
      this.status = "update";
      this.statusId = recordId;
  
      // fetch the record details and set them to the form
      const record = this.inventory.find(inv => inv.id === recordId);
      this.entityname = record.entityname;
      this.classification = record.classification;
      this.code = record.code;
      this.article = record.article;
      this.particulars = record.particulars;
      this.modelno = record.modelno;
      this.serialno = record.serialno;
      this.propertynumber = record.propertynumber;
      this.propertydate = record.propertydate;
      this.quantity = record.quantity;
      this.unit = record.unit;
      this.unitcost = record.unitcost;
      this.totalcost = record.totalcost;
      this.imagePreview = `https://inventrack.online/backend/uploads/${record.image}`;
      this.showAddItemModal = true; // Ito ang nagtatakda na ipapakita ang modal
  
      console.log(recordId);
  },

  
      resetForm() {
              this.entityname = "";
              this.classification = "";
              this.code = "";
              this.article = "";
              this.particulars = "";
              this.modelno = "";
              this.serialno = "";
              this.propertynumber = "";
              this.propertydate = "";
              this.quantity = "";
              this.unit = "";
              this.unitcost = "";
              this.totalcost = "";
              this.cameraStarted = false;
              this.capturedImage = null;
              this.uploadedImage = null;
              this.imagePreview = "";
              this.showAddItemModal = false;
          },
  
      
      async deleteRecord(recordId){
        const confirm = window.confirm("Are you sure that you want to delete this record?");
        if(confirm){
          const ins = await axios.post('delInventory', {
            id: recordId,
        });
        this.getInventory();
        }
      },
  
  
      async updateAvailability(id, newAvailability) {
        try {
          const response = await axios.post('updateAvailability', { id, availability: newAvailability });
          if (response.status === 200) {
            console.log(response.data);
            this.getInventory(); // Assuming this function fetches the updated data
          } else {
            console.error('Error updating availability:', response.data.error);
          }
        } catch (error) {
          console.error('Network error:', error.message);
        }
      },
  
      async getUserInfo(id){
                try {
                    const inf = await axios.get(`getDataUser?id=${id}`);
                    this.info = inf.data;
                } catch (error) {
                    console.log(error);
                }
            },
  
        async user(){
          try{
            const id= sessionStorage.getItem("token")
            const response = await axios.get(`/users/${id}`, {
              id:id
            })
            this.infos = response.data;
  
          }catch(error){
            console.log(error);
          }
        },
        getImageStyle(imageUrl) {
        // Function to generate the background image style
          if (!imageUrl) {
            return {}; // Return an empty object if imageUrl is not provided
          }
          
          // Set the background image URL
          const backgroundImage = `url('https://inventrack.online/backend/uploads/${imageUrl}')`;
          
          // Set background size and position
          const backgroundSize = 'cover'; // Cover the entire container
          const backgroundPosition = '50% 50%'; // Center the image
          
          // Return the style object
          return {
            width: '100%',
            height: '100%',
            backgroundImage,
            backgroundSize,
            backgroundPosition,
            borderRadius: '50%' // Make the background circular
          };
        },
  
        async logout(){
          sessionStorage.removeItem('token');
          this.$router.push('/signin');
         },
  },
  beforeDestroy() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    },
  }
  </script>

 
<style scoped>
.page-link {
  z-index: 0;
}


.qr-code-container {
  width: 300px;
  height: 300px;
  margin: 0 auto; /* Center the QR code */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ccc; /* To show a border when QR code is not visible */
}

.qr-img {
  width: 300px;
  height: 300px;
}


.form-group {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-label {
  font-weight: bold;
  color: #333;
}

.image-source-options {
  gap: 20px;
}

.radio-label {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 20px;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.radio-input {
  display: none;
}

.radio-input:checked + .radio-label {
  background-color: #007bff;
  color: #ffffff;
}

.file-input {
  padding: 8px;
  border-radius: 6px;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 8px;
  background-color: #e9ecef;
}

.camera-controls {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
  height: 85%;
}

.image-preview {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Backdrop for darkening effect */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with opacity */
  z-index: 1040; /* Ensure it sits behind the modal */
}

/* Adjust z-index of modal for stacking */
.modal {
  z-index: 1050; /* Ensure the modal is on top of the backdrop */
}

/* Slide transition (if needed) */
.modal-slide-enter-active {
  animation: slide-down 0.5s ease-out forwards;
}

.modal-slide-leave-active {
  animation: slide-up 0.5s ease-in forwards;
}

/* Keyframes for slide down and slide up */
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}


@media (max-width: 767px) {
  .modal-dialog {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Allow height to adjust based on content */
  }
}


.page-records {
  text-align: left;
  float: left;
}

/* Container for the button */
.button-container {
  display: flex;
  justify-content: flex-end; /* Aligns items to the far right */
  padding: 10px; /* Optional padding for spacing */
}

/* Style for the button */
button.noselect {
  width: 140px; /* Adjust as needed */
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #00a600;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  position: relative; /* To position the icon absolutely within the button */
}

button.noselect,
button.noselect span {
  transition: 200ms;
}

button.noselect .text {
  color: white;
  font-weight: bold;
  font-size: 15px; /* Reduced font size */
  margin-left: 15px; /* Adjust as needed */
}

button.noselect .icon {
  position: absolute;
  right: 10px; /* Position the icon inside the button */
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.noselect svg {
  width: 15px;
  fill: #eee;
}

button.noselect:hover {
  background: #00a600;
}

button.noselect:active {
  background: #00cc00;
}

button.noselect:hover .text {
  color: transparent;
}

button.noselect:hover .icon {
  left: 50%;
  transform: translateX(-65%); /* Centering the icon on hover */
  width: 40px; /* Keeps the icon size consistent */
  border-left: none;
}

button.noselect:focus {
  outline: none;
}

button.noselect:active .icon svg {
  transform: scale(0.8);
}

.buttonSpan {
  color: white;
  font-size: 24px;
}

.pagination .page-item {
  display: inline-block;
  margin-right: 5px; /* Paggalang sa espasyo sa pagitan ng mga button */
}

.pagination .page-link {
  border: 1px solid #ced4da; /* Bawasan ang lapad ng border */
  color: #343a40; /* Itim na kulay ng text */
  border-radius: 0; /* Alisin ang radius ng border */
}

.pagination .page-link:hover {
  background-color: transparent; /* Alisin ang background color sa hover */
}

.pagination .page-item.disabled .page-link {
  pointer-events: none; /* Huwag pahintulutan ang pag-click kung ang button ay hindi aktibo */
}

.pagination .page-item.active .page-link {
  background-color: transparent; /* Alisin ang background color ng active button */
  border-color: #ced4da; /* Bawasan ang lapad ng border ng active button */
}

.pagination .page-link:focus {
  box-shadow: none; /* Alisin ang focus border */
}

.pagination .page-link.prev, .pagination .page-link.next {
  padding: 0; /* Alisin ang padding */
  border: none; /* Alisin ang border */
  background: none; /* Alisin ang background */
  font-size: 1.5rem; /* Taasan ang laki ng font */
  color: #343a40; /* Itim na kulay ng text */
}

.pagination .page-link.prev::after, .pagination .page-link.next::after {
  content: '\2190'; /* Isama ang Unicode character para sa arrow (left arrow) */
}

.pagination .page-link.next::after {
  content: '\2192'; /* Isama ang Unicode character para sa arrow (right arrow) */
}

.button-container {
  margin-left: auto; /* Align the Add Item button to the right */
}

.input-group {
  margin-top: 10px; /* Space between the search bar and dropdowns */
}

.InputContainer {
  width: 210px; /* Increased width */
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgb(227, 213, 255), rgb(255, 231, 231));
  border-radius: 27px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.075);
}

.input {
  width: 200px; /* Increased width */
  height: 35px;
  border: none;
  outline: none;
  caret-color: rgb(255, 81, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 27px;
  padding-left: 12px;
  letter-spacing: 0.8px;
  color: rgb(19, 19, 19);
  font-size: 13.8px;
}

.button-group {
  display: flex;
  justify-content: center; /* Center align the buttons */
  gap: 1rem; /* Space between buttons */
}

.button, .button1 {
  width: 120px;
  height: 40px;
  font-size: 14px;
  padding: 0.5rem 1rem; /* Adjust as needed */
}

/* Other existing styles remain the same */
.button {
  border: none;
  background-color: seagreen;
  color: white;
  font-size: 0.9rem; /* Slightly larger font size */
  font-weight: 500;
  border-radius: 0.35rem; /* Slightly larger border radius */
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: translate(1) translate(0, 0);
  transition: transform 225ms, box-shadow 225ms;
}

.button:hover {
  transform: scale(1.05) translate(0, -0.1rem);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.35);
}

.button:active {
  transform: scale(1) translate(0, 0.1rem);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.15);
}

.button1 {
  color: white;
  background-color: #C0392B;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 0.904rem; /* Smaller font size */
  line-height: 1.7rem; /* Adjusted line height */
  padding-left: 1rem; /* Reduced padding */
  padding-right: 1rem; /* Reduced padding */
  padding-top: 0.5rem; /* Reduced padding */
  padding-bottom: 0.5rem; /* Reduced padding */
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;

}


.button1:hover {
  background-color: maroon;
}

.button1 svg {
  display: inline;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.75rem;
  color: white;
}

.button1:focus svg {
  animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button, .button1 {
  width: 120px;
  height: 40px;
  font-size: 14px;
  padding: 0.5rem 1rem; /* Adjust as needed */
}


/* For the container */
.table-container {
  position: relative;
  overflow-x: auto; /* Ensure horizontal scroll works */
}

/* For the sticky columns */
.sticky-col {
  position: sticky;
  right: 0;
  background-color: white; /* Set background color to white */
  z-index: 0; /* Ensure it stays on top of scrolling content */
  border-left: 2px solid #ccc; /* Add left border for the sticky column */
}

/* Adjust the position for the second-to-last column */
.sticky-col:nth-last-child(2) {
  right: 100px; /* Adjust this based on your column widths */
  background-color: white; /* Ensure background is also white for this column */
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.2); /* Same shadow for consistency */
  border-left: 2px solid #ccc; /* Add left border for the sticky column */
}

/* Additional styles for header cells in sticky columns */
th.sticky-col {
  background-color: white; /* Background for header of sticky columns */
  z-index: 0; /* Ensure headers are above body rows */
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.25); /* Slightly stronger shadow for headers */
  border-left: 2px solid #ccc; /* Add left border for sticky header */
}


/* General table styling */
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px; /* Adjust font size as needed */
}

th, td {
  padding: 3px 7px; /* Adjust padding for better readability */
  text-align: center;
  white-space: nowrap; /* Prevent line breaks in cells */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Show ellipsis for overflowed content */
}

th {
  height: auto; /* Adjust height for header */
  white-space: nowrap;
}

/* Add shadow and padding to each row */
tbody tr {
  padding: 10px; /* Add padding to the row */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow to the bottom */
  margin-bottom: 10px; /* Add space between rows */
  transition: box-shadow 0.3s; /* Smooth shadow transition on hover */
}

tbody tr:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}





/* Optional: Add a wrapper for horizontal scrolling */
.wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on mobile */
} 
 .container-inventory{
    flex-wrap: wrap;

 
   
  }

@media (max-width: 600px) {

 
  .form-select{
    width: 70%;
    padding: 10px;
    font-size: .6rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
  }
  .InputContainer{
    margin-top: 10px;
  }
  .me-2,.ms-2{
    font-size: 13px
  }
  .container-inventory{
    flex-wrap: wrap;
    gap: 10px;
   
  }
  .filters-container {
    flex-direction: column; /* Stack filters vertically */
    align-items: stretch; /* Stretch to full width */
  }

  .filters-container select {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 10px; /* Space between selects */
  }

  .InputContainer {
    width: 100%; /* Full width for search bar */
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .button1 {
    width: 100%; /* Full width for buttons */
    margin-bottom: 10px; /* Space between buttons */
  }
  .InputContainer{
    width: 210px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
  }
  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to full width */
  }

  .button {
    width: 100%; /* Full width for buttons */
    margin-bottom: 10px; /* Space between buttons */
  }

  .form-label {
    font-size: 1rem; /* Adjust label font size */
  }

  .form-control, .form-select {
    font-size: 1rem; /* Adjust input font size */
    padding: 0.5rem; /* Padding for inputs */
  }

  .img-fluid {
    max-width: 100%; /* Ensure images do not overflow */
    height: auto; /* Maintain aspect ratio */
  }

  /* Adjust video for responsiveness */
  video {
    max-width: 100%; /* Video responsive */
    height: auto; /* Maintain aspect ratio */
  }

}


.inactive-row {
  background-color: #f0f0f0; /* Light gray background */
  color: #a9a9a9; /* Darker gray text */
  opacity: 0.6; /* Optional: slightly faded appearance */
}

</style>
